<template>
  <b-modal
    id="customer-center-add-modal"
    hide-footer
    title="Thêm khách hàng trung tâm"
    size="xl"
    @hide="resetModal"
  >
    <b-container>
      <b-row>
        <b-col cols="2">
          <b-row>
            <b-col>
              <basic-date-picker
                format="DD/MM/YYYY"
                name="startDate"
                label="Ngày"
                placeholder="Nhập ngày"
                v-model="form.startDate"
                v-validate="'required'"
                :required="true"
                data-vv-as="Ngày"
                :state="validateState('startDate')"
                value-type="format"
                :invalidFeedback="errors.first('startDate')"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2">
          <b-row>
            <b-col>
              <label>Nhập thời gian</label>
              <input
                type="time"
                class="form-control"
                v-model="form.startTime"
                name="startTime"
                placeholder="Nhập giờ"
                required
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                required
                v-model="form.fullName"
                name="fullName"
                placeholder="Nhập Họ và tên"
                :value.sync="form.fullName"
                label="Họ và Tên"
                v-validate="'required'"
                data-vv-as="Họ và Tên"
                :state="validateState('fullName')"
                :invalidFeedback="errors.first('fullName')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Số điện thoại"
                v-model="form.phoneNumber"
                label="Số điện thoại"
                inputType="number"
                v-validate="'min: 10|max: 11'"
                maxlength="11"
                minlength="10"
                name="phoneNumber"
                data-vv-as="Số điện thoại"
                :state="validateState('phoneNumber')"
                :invalidFeedback="errors.first('phoneNumber')"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col cols="6">
              <basic-input
                placeholder="Năm sinh"
                v-model="form.yearOfBirth"
                inputType="number"
                pattern="\d*"
                label="Năm sinh"
                name="yearOfBirth"
                data-vv-as="Năm sinh"
                v-validate="{
                  numeric: true,
                  min_value: 1900,
                  max_value: new Date().getFullYear() - 1,
                }"
                :state="validateState('yearOfBirth')"
                :invalidFeedback="errors.first('yearOfBirth')"
                @input="form.age = new Date().getFullYear() - form.yearOfBirth"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
            <b-col cols="6">
              <basic-input
                placeholder="Tuổi"
                v-model="form.age"
                inputType="number"
                pattern="\d*"
                label="Tuổi"
                name="age"
                data-vv-as="Tuổi"
                v-validate="'numeric|min_value:1|max_value:124'"
                :state="validateState('age')"
                :invalidFeedback="errors.first('age')"
                @input="form.yearOfBirth = new Date().getFullYear() - form.age"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="Người được tư vấn"
                name="consultee"
                :options="consulteeOpts"
                :state="validateState('consultee')"
                :invalidFeedback="errors.first('consultee')"
                label="Người được tư vấn"
                data-vv-as="Người được tư vấn"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.consultee"
                :value.sync="form.consultee"
                @update:value="selected($event, 'consultee', consulteeOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Chọn tình trạng ---"
                name="status"
                :options="statusTypeOpts"
                v-validate="'required'"
                :state="validateState('status')"
                :invalidFeedback="errors.first('status')"
                :required="true"
                label="Tình trạng"
                data-vv-as="Tình trạng"
                :allowEmpty="false"
                track-by="value"
                v-model="form.status"
                :value.sync="form.status"
                changeValueByObject
                @update:value="selected($event, 'status', statusTypeOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" v-if="isOtherStatus">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập tình trạng khác"
                v-model="form.otherStatus"
                label="Khác"
                :required="true"
                v-validate="'required'"
                name="otherStatus"
                data-vv-as="Tình trạng khác"
                :state="validateState('otherStatus')"
                :invalid-feedback="errors.first('otherStatus')"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Năm phát hiện"
                v-model="form.yearOfDiscovery"
                inputType="number"
                label="Năm phát hiện"
                name="yearOfDiscovery"
                data-vv-as="Năm phát hiện"
                :required="false"
                v-validate="{
                  numeric: true,
                  min_value: form.yearOfBirth,
                  max_value: new Date().getFullYear(),
                }"
                :state="validateState('yearOfDiscovery')"
                :invalidFeedback="errors.first('yearOfDiscovery')"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              >
              </basic-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-date-picker
                format="DD/MM/YYYY"
                name="nextDate"
                label="Ngày tái khám"
                placeholder="Nhập ngày"
                v-model="form.nextDate"
                data-vv-as="Ngày"
                value-type="format"
                :invalidFeedback="errors.first('nextDate')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Bệnh kèm theo ---"
                name="diseases"
                :options="diseasesOpts"
                :state="validateState('diseases')"
                :invalidFeedback="errors.first('diseases')"
                label="Bệnh kèm theo"
                :allowEmpty="true"
                track-by="value"
                valueLabel="text"
                data-vv-as="Bệnh kèm theo"
                :multiple="true"
                v-model="form.diseases"
                :value.sync="form.diseases"
                @update:value="selected($event, 'diseases', diseasesOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="3"
          v-if="form.diseases && form.diseases.includes('Bệnh khác')"
        >
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập bệnh kèm theo"
                v-model="form.otherDiseases"
                label="Khác"
                :required="true"
                v-validate="'required'"
                name="otherDiseases"
                data-vv-as="Bệnh kèm theo"
                :state="validateState('otherDiseases')"
                :invalidFeedback="errors.first('otherDiseases')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col>
              <basic-input
                placeholder="HbA1C"
                v-model="form.hbA1C"
                inputType="number"
                label="HbA1C"
                name="hbA1C"
                data-vv-as="HbA1C"
                v-validate="{
                  decimal: true,
                  min_value: 0,
                  max_value: 30,
                }"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
                :state="validateState('hbA1C')"
                :invalidFeedback="errors.first('hbA1C')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Đường huyết đói"
                v-model="form.bloodSugar"
                inputType="number"
                label="Đường huyết đói"
                name="bloodSugar"
                data-vv-as="Đường huyết đói"
                :required="false"
                v-validate="{
                  decimal: true,
                  min_value: 0,
                  max_value: 999,
                }"
                :state="validateState('bloodSugar')"
                :invalidFeedback="errors.first('bloodSugar')"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <basic-select
            placeholder="--- Đơn vị ---"
            name="unit"
            :options="unitOpts"
            :state="validateState('unit')"
            :invalidFeedback="errors.first('unit')"
            label="Đơn vị"
            :allowEmpty="false"
            track-by="value"
            valueLabel="text"
            data-vv-as="Đơn vị"
            v-model="form.unit"
            :value.sync="form.unit"
            @update:value="selected($event, 'unit', unitOpts)"
            changeValueByObject
          />
        </b-col>
        <!-- Huyết app -->
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row no-gutters>
            <b-col cols="12" class="pl-0">
              <p style="margin-top: -0.5rem !important">Huyết áp (mmHg)</p>
            </b-col>
            <b-col cols="5" class="pr-1">
              <basic-input
                placeholder="max"
                v-model="form.systolic"
                inputType="number"
                name="systolic"
                v-validate="'numeric|min_value:1|max_value:999'"
                :state="validateState('systolic')"
                :invalidFeedback="errors.first('systolic')"
                data-vv-as="Huyết áp tối đa"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
            <b-col cols="5" class="pl-1">
              <basic-input
                placeholder="min"
                v-model="form.diastolic"
                inputType="number"
                name="diastolic"
                v-validate="'numeric|min_value:1|max_value:999'"
                :state="validateState('diastolic')"
                :invalidFeedback="errors.first('diastolic')"
                data-vv-as="Huyết áp tối thiểu"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                v-validate="'required'"
                :required="true"
                placeholder="--- Chọn trung tâm ---"
                name="agencyId"
                :options="agencies"
                :state="validateState('agencyId')"
                :invalidFeedback="errors.first('agencyId')"
                label="Trung Tâm"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                data-vv-as="Trung Tâm"
                v-model="form.agencyId"
                :value.sync="form.agencyId"
                changeValueByObject
                @update:value="
                  selected($event, 'agencyId', agencies);
                  form.sourceId = null;
                  form.instructorId = null;
                "
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="---Người chỉ định---"
                name="sourceId"
                :options="filteredSources"
                v-validate="'required'"
                :state="validateState('sourceId')"
                :invalidFeedback="errors.first('sourceId')"
                :required="true"
                label="Người chỉ định"
                data-vv-as="Người chỉ định"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.sourceId"
                :value.sync="form.sourceId"
                @update:value="selected($event, 'source', sources)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="---Người hướng dẫn---"
                name="instructorId"
                :options="filteredSources"
                :state="validateState('instructorId')"
                :invalidFeedback="errors.first('instructorId')"
                label="Người hướng dẫn"
                data-vv-as="Người hướng dẫn"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.instructorId"
                :value.sync="form.instructorId"
                @update:value="selected($event, 'instructorId', sources)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="doctorOrderOpts && doctorOrderOpts.length > 0">
          <b-row>
            <b-col>
              <b-form-group
                style="margin-left: 0.5rem !important"
                label="Chỉ định tư vấn"
              >
                <b-form-checkbox-group
                  :id="`checkbox-group`"
                  :name="`checkbox-group`"
                  v-model="form.doctorOrderIds"
                  :options="doctorOrderOpts"
                  value-field="value"
                  stacked
                  class="ml-10"
                  text-field="text"
                  label="Chỉ định tư vấn"
                  style="margin-left: 0.5rem !important"
                >
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-radio
                label="Máy đo Đường Huyết:"
                :labelCols="3"
                inline
                name="bloodGlucoseMeter"
                v-model="form.bloodGlucoseMeter"
                :value.sync="form.bloodGlucoseMeter"
                :options="radioOption"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" v-if="form.bloodGlucoseMeter == 2">
          <basic-input
            placeholder="Nhập lý do"
            v-model="form.reason"
            label="Lý do"
            :required="true"
            v-validate="'required'"
            name="reason"
            data-vv-as="Lý do"
            :state="validateState('reason')"
            :invalidFeedback="errors.first('reason')"
          />
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-radio
                label="Theo dõi Đường Huyết tại nhà:"
                :labelCols="3"
                inline
                name="monitorBloodSugar"
                v-model="form.monitorBloodSugar"
                :value.sync="form.monitorBloodSugar"
                :options="radioOption"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
        <!-- Huyết app -->
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-radio
                label="Máy đo Huyết áp:"
                :labelCols="3"
                inline
                name="bloodPressureDevice"
                v-model="form.bloodPressureDevice"
                :value.sync="form.bloodPressureDevice"
                :options="radioOption"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-radio
                label="Theo dõi Huyết áp tại nhà:"
                :labelCols="3"
                inline
                name="monitorBloodPressure"
                v-model="form.monitorBloodPressure"
                :value.sync="form.monitorBloodPressure"
                :options="radioOption"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score-isInPatient"
                v-model="form.isInPatient"
                name="isInPatient"
              >
                Nội trú
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score-isOutPatient"
                v-model="form.isOutPatient"
                name="isOutPatient"
              >
                Ngoại trú
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score"
                v-model="form.purchase"
                name="purchase"
              >
                Mua hàng
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-row style="margin-top: 20px">
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-download-app"
                v-model="form.downloadApp"
                name="downloadApp"
              >
                Tải App
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row style="margin-top: 20px">
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-zalo"
                v-model="form.zalo"
                name="zalo"
              >
                Tham gia Zalo
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row>
            <b-col>
              <basic-radio
                label="Khách mới/cũ"
                :labelCols="3"
                inline
                name="newCustomer"
                v-model="form.newCustomer"
                :value.sync="form.newCustomer"
                :options="options"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <basic-input
                placeholder="Ghi chú"
                v-model="form.customerConcerns"
                name="customerConcerns"
                data-vv-as="Ghi chú"
                label="Nhập ghi chú"
                :state="validateState('customerConcerns')"
                :invalidFeedback="errors.first('customerConcerns')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          @click.stop="handelValidation"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          @click.stop="handelValidation(true)"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu & Tiếp Tục
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { CUSTOMER_STATUS_TYPE, DISEASES } from './CustomerCenterEnum';
export default {
  props: {
    agencies: {
      type: Array,
      default: () => [],
    },
    sources: {
      type: Array,
      default: () => [],
    },
    doctorOrders: {
      type: Array,
      default: () => [],
    },
  },
  name: 'CustomerCenterAdd',
  data() {
    return {
      defaultValue: {
        newCustomer: 1,
      },
      loading: false,
      form: {        
        age:null,
        monitorBloodPressure: null,
        bloodPressureDevice: null,
        diastolic: null,
        systolic: null,
        startTime: this.$moment().format('HH:mm'),
        startDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        fullName: '',
        phoneNumber: null,
        yearOfBirth: null,
        hbA1C: null,
        fromDoctor: '',
        customerConcerns: '',
        purchase: false,
        downloadApp: false,
        status: {
          name: 'Đái tháo đường típ 2',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2,
        },
        sourceId: null,
        otherStatus: null,
        agencyId: null,
        doctorOrderIds: [],
        yearOfDiscovery: null,
        diseases: [],
        otherDiseases: null,
        bloodSugar: null,
        unit: { text: 'mg/dL', value: 'mg/dL' },
        instructorId: null,
        bloodGlucoseMeter: null,
        monitorBloodSugar: null,
        isInPatient: false,
        reason: null,
        zalo: false,
        isOutPatient: false,
        consultee: null,
        nextDate: moment().add(28, 'days').format('DD/MM/YYYY'),
        newCustomer: 1,
      },
      doctorOrderOpts: [],
      radioOption: [
        { name: 'Có', value: 1 },
        { name: 'Không', value: 2 },
      ],
      options: [
        { name: 'Mới', value: 1 },
        { name: 'Cũ', value: 2 },
      ],
    };
  },
  computed: {
    filteredSources() {
      if (this.form.agencyId) {
        const selectedAgency = this.agencies.find(
          (agency) => agency.value === this.form.agencyId.value,
        );
        return this.sources.filter(
          (source) => source.group.name === selectedAgency.value,
        );
      }
      return this.sources;
    },

    isOtherStatus() {
      return this.form.status?.value === 'Khác';
    },
    statusTypeOpts() {
      return [
        {
          name: 'Tiền đái tháo đường',
          value: CUSTOMER_STATUS_TYPE.TIEN_DAI_THAO_DUONG,
        },
        {
          name: 'Đái tháo đường típ 1',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_1,
        },
        {
          name: 'Đái tháo đường típ 2',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2,
        },
        {
          name: 'Đái tháo đường thai kì',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_THAI_KI,
        },
        { name: 'Tăng huyết áp', value: CUSTOMER_STATUS_TYPE.TANG_HUYET_AP },
        { name: 'Béo phì', value: CUSTOMER_STATUS_TYPE.BEO_PHI },
        {
          name: 'Rối loạn mỡ máu',
          value: CUSTOMER_STATUS_TYPE.ROI_LOAN_MO_MAU,
        },
        { name: 'Khác', value: CUSTOMER_STATUS_TYPE.KHAC },
      ];
    },
    diseasesOpts() {
      return [
        {
          text: 'Tăng mỡ máu (cholesterol/triglyceride)',
          value: DISEASES.TANG_MO_MAU,
        },
        { text: 'Bệnh tim mạch', value: DISEASES.BENH_TIM_MACH },
        {
          text: 'Bệnh thận (suy thân, bệnh thận mạn)',
          value: DISEASES.BENH_THAN,
        },
        { text: 'Bệnh mắt', value: DISEASES.BENH_MAT },
        { text: 'Bệnh tuyến giáp, viêm giáp', value: DISEASES.BENH_TUYEN_GIAP },
        {
          text: 'Bệnh thần kinh ngoại biên (tê chân, mất cảm giác bàn chân)',
          value: DISEASES.BENH_THAN_KINH_NGOAI_BIEN,
        },
        { text: 'Rối loạn lo âu, trầm cảm', value: DISEASES.BENH_TRAM_CAM },
        { text: 'Bệnh răng miệng', value: DISEASES.BENH_RANG_MIENG },
        { text: 'Ung thư', value: DISEASES.BENH_UNG_THU },
        { text: 'Bệnh khác', value: DISEASES.BENH_KHAC },
      ];
    },
    unitOpts() {
      return [
        { text: 'mg/dL', value: 'mg/dL' },
        { text: 'mmol/L', value: 'mmol/L' },
      ];
    },
    consulteeOpts() {
      return [
        { text: 'Người bệnh', value: 'Người bệnh' },
        { text: 'Người thân', value: 'Người thân' },
        { text: 'Cả 2', value: 'Cả 2' },
      ];
    },
  },
  watch: {
    doctorOrders: {
      deep: true,
      handler(val) {
        this.doctorOrderOpts = val.filter((m) => {
          return m.group.name === CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2;
        });
      },
    },
    'form.yearOfBirth'(newValue) {
      if (!newValue) {
        this.form.age = null;
      }
    },
    'form.age'(newValue) {
      if (!newValue || newValue == new Date().getFullYear().toString()) {
        this.form.yearOfBirth = null;
      }
    },
  },

  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation(isCoutinue = false) {
      this.$validator.validateAll().then((result) => {
        if (!result) return;
        this.handleSubmit(isCoutinue);
        console.log('handelValidation');
      });
    },
    async handleSubmit(isCoutinue = false) {
      console.log('handleSubmitFalse');
      var payload = {
        fullName: this.form.fullName,
        phoneNumber: this.form.phoneNumber,
        startDate: this.convertDateToTimestamp(this.form.startDate),
        startTime: this.form.startTime,
        monitorBloodPressure: this.form.monitorBloodPressure,
        bloodPressureDevice: this.form.bloodPressureDevice,
        diastolic: this.form.diastolic,
        systolic: this.form.systolic,
        hba1c: this.form.hbA1C,
        fromDoctor: this.form.fromDoctor,
        customerConcerns: this.form.customerConcerns,
        purchase: this.form.purchase,
        status: this.form.status.value,
        sourceId: this.form.sourceId,
        otherStatus: this.form.otherStatus,
        yearOfBirth: this.form.yearOfBirth,
        agencyId: this.form.agencyId.value,
        doctorOrderIds: this.form.doctorOrderIds,
        downloadApp: this.form.downloadApp ?? false,
        diseases: this.form.diseases,
        otherDiseases: this.form.otherDiseases,
        bloodSugar: this.form.bloodSugar,
        unit: this.form.unit?.value ?? null,
        instructorId: this.form.instructorId,
        bloodGlucoseMeter: this.form.bloodGlucoseMeter,
        reason: this.form.reason,
        monitorBloodSugar: this.form.monitorBloodSugar,
        isInPatient: this.form.isInPatient ?? false,
        yearOfDiscovery: this.form.yearOfDiscovery,
        zalo: this.form.zalo,
        isOutPatient: this.form.isOutPatient,
        consultee: this.form.consultee,
        newCustomer: this.form.newCustomer == 2 ? false : true, // change value to boolean
        nextDate:
          this.form.nextDate != null
            ? this.convertDateToTimestamp(this.form.nextDate)
            : null,
      };
      try {
        await this.$api.post('CustomerCenter', payload);
        if (isCoutinue === true) await this.resetModal();
        else this.handleCancel();
        this.$emit('loadData');

        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Thêm mới khách hàng trung tâm thành công.',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    selected(value, formProperty, optionsArr) {
      if (formProperty === 'status') {
        this.form.doctorOrderIds = [];
        this.form.selectedDoctorOrders = [];
        this.doctorOrderOpts = this.doctorOrders.filter((m) => {
          return m.group.name === value.value;
        });
      }
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value === value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },

    async resetModal() {
      this.form = {
        monitorBloodPressure: null,
        bloodPressureDevice: null,
        diastolic: null,
        systolic: null,
        startTime: this.$moment().format('HH:mm'),
        startDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        fullName: '',
        phoneNumber: null,
        yearOfBirth: null,
        hbA1C: null,
        fromDoctor: '',
        customerConcerns: '',
        purchase: false,
        downloadApp: false,
        status: {
          name: 'Đái tháo đường típ 2',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2,
        },
        sourceId: null,
        otherStatus: null,
        agencyId: null,
        doctorOrderIds: [],
        yearOfDiscovery: null,
        diseases: [],
        otherDiseases: null,
        bloodSugar: null,
        unit: { text: 'mg/dL', value: 'mg/dL' },
        instructorId: null,
        bloodGlucoseMeter: null,
        monitorBloodSugar: null,
        isInPatient: false,
        reason: null,
        zalo: false,
        isOutPatient: false,
        consultee: null,
        nextDate: moment().add(28, 'days').format('DD/MM/YYYY'),
        newCustomer: 1,
      };

      this.doctorOrderOpts = this.doctorOrders.filter((m) => {
        return m.group.name === CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2;
      });

      // Reset validation
      this.$validator.reset();
    },

    async handleCancel() {
      await this.resetModal();
      this.$bvModal.hide('customer-center-add-modal');
    },
  },
};
</script>
